/* eslint-disable max-lines */
// Routes is for the applications workflow definitions.
import React from 'react';
import Logo from './src/assets/images/logo.svg';

import { RouteBuilder, base } from '@fingermarkglobal/router';
// Custom views
import { CustomHomeView } from './src/views/home';
import { CustomBeaconView } from './src/views/beacon';
import { CustomReceiptView } from './src/views/receipt';

// Custom templates
import { CustomDefaultTemplate } from './src/templates/default';

import { Loading } from '@fingermarkglobal/kfc-styles';

import { version } from './package.json';
import { Warning } from './src/components/stateless/warning';

import mockSettings from './mock-settings.json';

const LogoComponent = () => <Logo data-test="logo" className="absolute top-0 w-auto h-64 mt-32" />;

const config = {
  ...base,
  home: {
    ...base.home,
    View: CustomHomeView,
    config: {
      ...base.home.config,
      provider: {
        allowedLanguages: ['tha', 'en'],
      },
    },
  },
  beacon: {
    ...base.beacon,
    View: CustomBeaconView,
    config: {
      ...base.beacon.config,
      provider: {
        primaryPath: '/categories',
        secondaryPath: '/reset',
      },
      template: {
        headerEnabled: false,
      },
    },
  },
  coupon: {
    ...base.coupon,
    config: {
      ...base.coupon.config,
    },
  },
  category: {
    ...base.category,
    config: {
      ...base.category.config,
      provider: { displayDescription: false, shouldChangeAddButtonText: true },
    },
  },
  customer: {
    ...base.customer,
    config: {
      ...base.customer.config,
      template: {
        ...base.customer.config.template,
        footerEnabled: true,
      },
      provider: {
        layout: 'TH',
        displayRequiredLabel: false,
        showPrimaryAction: true,
        showEmail: true,
        isFullNameRequired: true,
        showFullNameField: true,
      },
    },
  },
  admin: {
    ...base.admin,
    config: {
      ...base.admin.config,
      provider: { version },
    },
  },
  validate: {
    ...base.validate,
    config: {
      ...base.validate.config,
      provider: { image: LogoComponent, isLogoEnabled: true },
    },
  },
  confirmation: {
    ...base.confirmation,
    config: {
      ...base.confirmation.config,
      provider: { image: LogoComponent, isLogoEnabled: true },
    },
  },
  receipt: {
    ...base.receipt,
    View: CustomReceiptView,
  },
  composed: {
    ...base.composed,
    config: {
      ...base.composed.config,
      provider: {
        showCategoriesHeader: true,
      },
    },
  },
  lock: {
    ...base.lock,
    config: {
      ...base.lock.config,
      template: {
        ...base.lock.config.template,
        timeoutEnabled: false,
      },
    },
  },
  loyalty: {
    ...base.loyalty,
    config: {
      ...base.loyalty.config,
    },
  },
  offers: {
    ...base.offers,
    config: {
      ...base.offers.config,
    },
  },
};

const Routes = () => (
  <RouteBuilder
    Template={CustomDefaultTemplate}
    root={{
      ErrorComponent: Warning,
      resolverProps: { Loading, isRetryActive: true },
      overwriteSettings: mockSettings,
    }}
    config={config}
  />
);

export { Routes };
